<template>
  <div class="page page-box page-padding">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="课程大纲：">
          <el-input v-model="listQuery.Name" placeholder="请输入课程大纲名称" class="input-width" />
        </el-form-item>
        <el-form-item label="年龄段：">
          <el-select class="sl" v-model="listQuery.Ages" clearable placeholder="2011">
            <el-option v-for="item in AgeGroupList" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration">查询</el-button>
          <el-button class="plain-button" v-preventReClick @click="resetSearch">重置</el-button>
          <el-button @click="addOrEidtClubOutline()" icon="el-icon-plus">
            <!-- <div class="img-center"> <img src="@/assets/add.png" alt="添加" class="add-img"> <span>添加课程大纲</span></div> -->
            添加课程大纲
          </el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体 -->
    <main class="page-main-main" ref="main">
      <!-- 列表渲染 -->
      <el-table v-loading="loading" :data="tableList" border fit stripe>
        <el-table-column prop="Name" label="课程大纲名称" align="center" :show-overflow-tooltip="true" />
        <el-table-column prop="Ages" label="年龄段" align="center">
          <template slot-scope="{ row }">
            {{row.Ages}}年
          </template>
        </el-table-column>
        <el-table-column prop="CourseCount" label="建议课时" align="center" />
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="readDetail(row)"> 详情 </el-button>
            <el-button type="text" @click="addOrEidtClubOutline(row)"> 编辑 </el-button>
            <el-button type="text" @click="deleteClubOutline(row)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <footer>
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClubOutlineList" />
      </footer>
    </main>
    <!-- 新建编辑课程大纲 -->
    <el-dialog :title="ClubOutlineTitle" :visible.sync="ClubOutlineVisible" width="40%" :close-on-click-modal='false'>
      <el-form :model="ClubOutlineForm" :rules="rules" ref='ClubOutlineForm' label-width="100px">
        <el-row>
          <el-col :span="12" class="newCol">
            <el-form-item label="大纲名称:" prop="Name">
              <el-input v-model="ClubOutlineForm.Name" placeholder="请输入课程大纲名称" show-word-limit maxlength="20" />
            </el-form-item>
          </el-col>
          <el-col :span="12" class="newCol">
            <el-form-item label="年龄段:" prop="Ages">
              <el-select class="select-box" v-model="ClubOutlineForm.Ages" @change='changeAgesStatus' placeholder="请选择">
                <el-option v-for="item in AgeGroupList" :key="item" :label="item" :value="item" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="建议课时:" prop="CourseCount">
          <el-input type="number" v-model="ClubOutlineForm.CourseCount" placeholder="请输入建议课时" class="no-number" />
        </el-form-item>
        <el-form-item label="课程教案:" prop="Content">
          <el-input v-model="ClubOutlineForm.Content" :autosize="{ minRows: 15, maxRows: 5 }" type="textarea" placeholder="请输入课程教案" show-word-limit maxlength="1000" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ClubOutlineVisible = false" class="plain-button">取 消</el-button>
        <el-button v-preventReClick @click="addOrUpdateClubOutline()" class="discoloration">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog title="详情" :visible.sync="readVisible" width="40%" :close-on-click-modal='false'>
      <el-row class="margin-row">
        <el-col :span="12">
          <span>课程大纲名称：</span><span>{{ readSyllabusForm.Name }}</span>
        </el-col>
        <el-col :span="10" :offset="2">
          <span>年龄段：</span><span>{{ readSyllabusForm.Ages }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col>
          <span>建议课时：</span><span>{{ readSyllabusForm.CourseCount }}</span>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col>
          <h4>课程教案：</h4>
        </el-col>
      </el-row>
      <el-row class="margin-row">
        <el-col>
          <span style="white-space: pre-wrap;">{{ readSyllabusForm.Content }}</span>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { getClubOutlineList, addOrUpdateClubOutline, delClubOutline } from "@/service/teaching";
import pagination from '@/components/pagination/pagination.vue';  // 引入分页
import { deepClone } from '@/utils/index';  // 引入深拷贝

const ClubOutlineForm = { // 新增编辑课程大纲的初始化数据
  Name: '',
  Ages: (new Date()).getFullYear(), // 年龄段,初始为当前年
  CourseCount: '',
  Content: '',
};
const ListQuery = { // 查询数据
  PageNo: 1,
  PageSize: 50,
  Name: '',
  Ages: '', // 年龄段,初始

};
export default {
  name: "Syllabus",
  components: {
    pagination,
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入课程大纲名称'));
      }
      callback();
    };

    const validateAges = (rule, value, callback) => {
      if (this.ClubOutlineForm.Ages) {
        return callback();
      }
      callback(new Error('请选择年龄段'));
    };

    const validateCourseCount = (rule, value, callback) => {
      this.ClubOutlineForm.CourseCount = parseInt(this.ClubOutlineForm.CourseCount)
      if (this.ClubOutlineForm.CourseCount === 0 || this.ClubOutlineForm.CourseCount > 0) {
        return callback();
      }
      callback(new Error('建议课时应该大于0'));
    };

    const validateContent = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入课程教案'));
      }
      callback();
    };
    return {
      formInline: { // 状态
        region: ''
      },
      ClubOutlineForm: deepClone(ClubOutlineForm),
      listQuery: deepClone(ListQuery),
      tableList: [],  // 表格数据
      total: 0, // 表格总条数
      loading: false, // 加载状态
      ClubOutlineTitle: '新增课程大纲', // 新建编辑title
      ClubOutlineVisible: false,  // 新建编辑课程大纲弹窗状态
      rules: {
        Name: [{ required: true, validator: validateName, trigger: 'blur' }],
        Ages: [{ required: true, validator: validateAges, trigger: 'blur' }],
        CourseCount: [{ required: true, validator: validateCourseCount, trigger: 'blur' }],
        Content: [{ required: true, validator: validateContent, trigger: 'blur' }],
      },
      AgeGroupList: [], // 年龄段列表，从当前年往前推50年  
      readVisible: false, // 详情窗口状态
      readSyllabusForm: {}, // 详情数据
    }
  },
  created() {
    // 当前年往前推50年  
    for (let i = (new Date()).getFullYear(); i >= (new Date()).getFullYear() - 50; i--) {
      this.AgeGroupList.push(i)
    }
    this.getClubOutlineList();
  },
  methods: {
    /**
      * 获取课程大纲列表数据
      */
    async getClubOutlineList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClubOutlineList(this.listQuery);
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },

    /**
     * 页头-查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.Name = this.listQuery.Name && this.listQuery.Name.trim();
      this.listQuery.Ages = this.listQuery.Ages || undefined;
      this.getClubOutlineList();
    },

    /**
     * 页头-重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.getClubOutlineList();
    },

    /**
     * 操作-查看
     */
    readDetail(row) {
      this.readVisible = true;
      this.readSyllabusForm = { ...row };
    },

    /**
      * 操作/主要-新增或者编辑
      */
    addOrEidtClubOutline(row) {
      this.ClubOutlineVisible = true;
      this.$nextTick(() => {
        this.$refs.ClubOutlineForm.clearValidate();

      })
      if (row) {
        this.ClubOutlineForm = { ...row };
        this.ClubOutlineTitle = '编辑课程大纲';
      } else {
        this.ClubOutlineForm = deepClone(ClubOutlineForm);
        this.ClubOutlineTitle = '新增课程大纲';
      }
    },

    /**
     * 确定新增或者编辑
     */
    addOrUpdateClubOutline() {
      this.$refs.ClubOutlineForm.validate(async (valid) => {
        if (!valid) return;

        if (this.ClubOutlineTitle === '编辑课程大纲') {
          await addOrUpdateClubOutline({
            Id: this.ClubOutlineForm.Id,
            Ages: this.ClubOutlineForm.Ages,
            CourseCount: this.ClubOutlineForm.CourseCount,
            Name: this.ClubOutlineForm.Name,
            Content: this.ClubOutlineForm.Content,
          });
          this.$message.success('编辑成功');
        } else {
          await addOrUpdateClubOutline(this.ClubOutlineForm);
          this.$message.success('新建成功');
        }

        this.ClubOutlineVisible = false;
        this.getClubOutlineList();
      })

    },

    /**
     * 操作-删除
     */
    async deleteClubOutline(row) {
      try {
        let res = await this.$confirm('确定要删除吗？', '警告', {
          distinguishCancelAndClose: true,
          confirmButtonText: '删 除',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,

        })
        if (res !== 'confirm') return;
        await delClubOutline({ Id: row.Id });
        this.getClubOutlineList();
        this.$message({
          message: '删除成功',
          type: 'success'
        });
      } catch (err) { };
    },

    changeAgesStatus() {
      this.$refs.ClubOutlineForm.validateField('Ages')
    }
  },
}
</script>
<style lang='scss' scoped>
.newCol {
  padding: 0 !important;
}
/deep/.el-table__body tr,
.el-table__body td {
  height: 54px !important;
}
</style>